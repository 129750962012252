<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>
        <v-col cols="12" sm="12" md="8">
            <v-select
                :value="value"
                @input="handle"
                :items="filteredItems"
                :item-text="item => item.Value"
                item-value="id"                
                hide-details
                required
                outlined
                dense
                :rules="rules"
                append-icon="fas fa-chevron-down"
                :menu-props="{ bottom: true, offsetY: true }"
            >
                <template v-slot:prepend-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field 
                                :placeholder="$t('Поиск')"
                                class="mb-2 autocomplete-search"
                                hide-details
                                dense
                                clearable
                                autofocus
                                v-model="textsearch"
                            >
                            </v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>

        </v-col>
    </v-row>
</template>
<script>

import sys from '@/services/system';
export default {
    name: "FieldEditSelect",
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: Number,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        rules: null
    },
    data() {
        return {            
            textsearch: ""
        }
    },
    asyncComputed: {
        filteredItems: {
            async get () {
                if (this.textsearch)
                    return this.items.filter(i => sys.checkSearch(i.value, this.textsearch));
                else
                    return this.items;
            },
            default: [],
            watch: ['textsearch']
        },
    },
    methods: {
        handle(e) {
            this.$emit('input', e);
        }
    }
};
</script>

